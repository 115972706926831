import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'


// 获取logo
export const goginedImageUrl = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataConfig/LoginedImageUrl`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取企业秘钥
export const getSecretCode = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/UserRole/GetKey`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 生成企业秘钥
export const createSecretCode = () => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/UserRole/CreateKey`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 通知配置
export const EditUserKeyNotify = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/UserRole/EditUserKeyNotify`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 用户登出
export const Logout = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/Sys/Logout`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}