<template>
  <div class="homeNoContent">
    <img class="img" :src="configData.LogoURL" alt="">
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import {_getConfigData} from "@/utils/storage";
import { goginedImageUrl, Logout } from "@/api/index";
export default {
  mixins: [basicMixin],
  data() {
    return {
      configData:''
    };
  },
  methods: {

  
  },
  mounted() {
    goginedImageUrl().then((res) => {
      // let data = JSON.parse(_getConfigData());
      // data.PlatternHeadURL = res.LoginedSystemImageUrl;
      // this.configData = data;
      this.configData=res.SysConfigData;
    });
    // let data = _getConfigData()
    // this.configData = JSON.parse(data)
  },
};
</script>

<style lang="scss" scoped>
.homeNoContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: calc(100vh - 106px);
  // width: 80vw;
  .img {
    
  }
}
</style>